import { View } from '@react-pdf/renderer';
import Question from '@rsa-digital/evo-shared-components/components/pdf/components/Question';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

export type KeepingYouInformedSectionProps = {
  theme: DefaultTheme;
  sectionHeading: string;
  keepingYouInformedQuestion: string;
  keepingYouInformedAnswer: string;
  privacyNotice?: string;
};

const KeepingYouInformedSection: React.FC<KeepingYouInformedSectionProps> = ({
  privacyNotice,
  keepingYouInformedQuestion,
  keepingYouInformedAnswer,
  sectionHeading,
  theme,
}) => (
  <>
    <Section>
      <Heading2>{sectionHeading}</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      <Question question={keepingYouInformedQuestion} answer={keepingYouInformedAnswer} />
      {privacyNotice && (
        <>
          <View style={{ marginTop: 24 }}>
            <RichText html={privacyNotice} theme={theme} />
          </View>
        </>
      )}
    </Section>
  </>
);

export default KeepingYouInformedSection;
