import { Text } from '@react-pdf/renderer';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import { EmphasisParagraphText } from '@rsa-digital/evo-shared-components/components/pdf/components/typography';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import { Heading2 } from 'components/CheckYourDetailsPdf/typography';

export type DetailGroupSectionProps = {
  sectionHeading: string;
  details: Detail[];
  sectionDescription?: string;
  sectionDisclaimer?: string;
};

const DetailGroupSection: React.FC<DetailGroupSectionProps & { theme: DefaultTheme }> = ({
  theme,
  sectionHeading,
  details,
  sectionDescription,
  sectionDisclaimer,
}) => (
  <>
    <Section>
      <Heading2>{sectionHeading}</Heading2>
      <SimpleDivider theme={theme} />
      {sectionDescription && (
        <>
          <RichText html={sectionDescription} theme={theme} />
          <SimpleDivider theme={theme} />
        </>
      )}
      {details.map((detail) => (
        <Fragment key={detail.label}>
          <Text>
            {detail.label}
            <EmphasisParagraphText> {detail.value}</EmphasisParagraphText>
          </Text>
          <SimpleDivider theme={theme} />
        </Fragment>
      ))}
      {sectionDisclaimer && <RichText html={sectionDisclaimer} theme={theme} />}
    </Section>
  </>
);

export default DetailGroupSection;
